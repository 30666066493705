/* @import '~react-big-calendar/lib/sass/styles.scss'; */
/* @import '~react-big-calendar/lib/addons/dragAndDrop/styles'; */
/* // if using DnD */
/* @import '~bootstrap/dist/css/bootstrap.min.css' */
html {
  background-color:  #F5F5F5;

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}
main {
  background-color:  #F5F5F5;
}
